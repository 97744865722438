body {
    font-size: 14px;
    font-family: 'muliregular';
    color: $text-color;
}

h1 {
    font-size: 2em;
}

.check-icon {
    &::before {
        background-color: $dark-color;
    }
}
.card-body {
    border: 6px solid $border-color;
}
.questions {
    .alternate:nth-child(even) {
        background-color: $light-color;
    }
}
.footer-info {
    color: $light-color;
}
#breadcrumb {
    color: $light-color;
}
h1{
    color: $dark-color;
}
h2{
    color: $dark-color;
}
h3{
    color: $medium-color;
}

.smaller {
    font-size: 12px;
}

.smaller {
    font-size: 12px;
}

h3 {
    font-size: 15px;
}

.inline-icon {
    width: 28px;
    height: 28px;
    display: inline-block;
    margin-left: 0;
    padding-top: 3px;
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: 3px;

    &:hover {
        cursor: pointer;
        background-color: lightgray;
    }
}

.small-inline-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-left: 0;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 3px;

    &:hover {
        cursor: pointer;
        background-color: lightgray;
    }
}

.righttop-icon {
    float: right;
    width: 27px;
    height: 27px;
    background-size: 21px;
    background-repeat: no-repeat;
    background-position: 3px;

    &:hover {
        cursor: pointer;
    }
}

ul.no-bullets {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

ul.no-bullets > li > * {
    display: inline-block;
    vertical-align: middle;
}

.edit-icon {
    background-image: url('~bootstrap-icons/icons/pencil-square.svg');
}

.delete-icon {
    background-image: url('~bootstrap-icons/icons/x-square-fill.svg');
}

.play-icon {
    background-image: url('~bootstrap-icons/icons/play.svg');
}

.stop-icon {
    background-image: url('~bootstrap-icons/icons/stop-fill.svg');
}

.search-icon {
    background-image: url('~bootstrap-icons/icons/search.svg');
}

.location-icon {
    background-image: url('~bootstrap-icons/icons/house-door.svg');
}

.add-icon {
    background-image: url('~bootstrap-icons/icons/plus-square-fill.svg');
}

.telephone-icon {
    background-image: url('~bootstrap-icons/icons/telephone-fill.svg');
}

.check-icon {
    //background-image: url('~bootstrap-icons/icons/square-fill.svg');
    color: white;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        display: inline-block;
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: -1;
        border-radius: 3px;
        left: 2px;
    }
}

.mail-icon {
    background-image: url('~bootstrap-icons/icons/envelope-fill.svg');
}

.trigger-icon {
    background-image: url('~bootstrap-icons/icons/lightning-fill.svg');
}

.download-icon {
    background-image: url('~bootstrap-icons/icons/download.svg');
}

.check-empty {
    background-image: url('~bootstrap-icons/icons/square.svg');
    color: gray;
}

.info {
    background-image: url('~bootstrap-icons/icons/info-circle.svg');
}

#main {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    #big-title {
        color: white;
        width: 100%;
    }
}

.text-comment{
    flex: 0 0 200px;
}

#big-title {
    font-weight: bold;
    font-size: 96px;
    width: 100%;
    text-align: left;
}


#startbutton {
    display: block;
    float: right;
    background-color: white;
    border-bottom: 12px;
    opacity: 50%;
    font-weight: bold;
    height: 60px;
    font-size: 22px;
    -moz-border-radius-topleft: 12px;
    -moz-border-radius-bottomleft: 12px;
    border-radius: 12px;
    &:hover{
        opacity: 100%;
    }
}

@media screen and (max-width: 767px){
    .buttons-right {
        display: flex;
        flex-direction: column;
        button {
            width: 100%
        }
    }
}

@media screen and (min-width: 768px){
    .buttons-right {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: right;
    }
}

.main-button {
    height: 40px;
    width: 240px;
    font-size: 17px;
    margin-bottom: 5px;
    color: white;
}

.main-button:hover {
    color: white;
}

.heading {
    vertical-align: bottom;
    font-weight: bold;
    border-bottom: 1px solid gray;
    text-align: center;
}

.answer {
    padding: 2px 5px;
    flex: 1 1 20%;
    text-align: center;
}

.card {
    margin-top: 10px;
    //max-height: calc(100vh - 40px);
    .card-body {
        padding-top: 60px;
        @include media-breakpoint-up(sm) {
            padding-top: 20px;
        }
        position: relative;
        margin: 20px 20px 50px 20px;
        padding-bottom: 75px;

        border-radius: 20px;

        h2 {
            margin: 0;
        }
        h3 {
            margin: 0 0 8px 0;
        }
    }
}

.cardcontainer {
    max-height: 100%;
    overflow: scroll;

}

.card-header {
    position: relative;
}

.card-footer {
    min-height: 70px;
    position: relative;
}

.card-contents {
    //position: relative;
    overflow-y: auto;
    overflow-x: scroll;
    //max-height: calc(100vh - 425px);
}

.scoreform-contents {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    //max-height: calc(100vh - 20px);
}

#logo {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    top: 10px;
}

#zorgcompscanlogo {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: -50px;
    z-index: 1;
    margin: 0 0 0 -20px;
}

#breadcrumb{
    font-style: italic;
    position: absolute;
    bottom: -35px;
    right: 0px;
}

@media screen and (max-width: 507px) {
    #zorgcompscanlogo {
        bottom: -30px;
        img {
            width: 150px;
        }
    }
    #breadcrumb {
        position: absolute;
        bottom: -55px;
        width: 100%;
        text-align: center;
    }
}

#polls {
}

.poll-item {
    display: flex;
}
.poll-question {
    flex: 1;
    margin-right: auto;
}

.poll-smileys {
    flex: 0;
    flex-wrap: nowrap;
    padding: 0 10px;
    display: flex;
    justify-content: center;
}
.poll-answers {
    flex: 1;
}

@media screen and (min-width: 801px) {
    .poll-item {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 800px) {
    .poll-item {
        display: flex;
        flex-direction: column;
        justify-items: center;
        gap: 10px;
        margin-bottom: 30px;

    }
    .poll-question {
        flex: 0;
        margin-right: 0;
        text-align: center;
    }
    .poll-answers {
        flex: 0;
    }
}

@include media-breakpoint-down(sm) {
    .inschatting {
        display: none;
    }
}

.question {
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 0;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }

    &.scan {
        .question-text {
            flex: 0 1 70%;
        }

        .answers {
            flex: 1 0 30%;
            margin: auto;
        }
    }
    &.assess {
        .question-text {
            flex: 0 1 60%;
        }

        .answers {
            flex: 1 0 40%;
            margin: auto;
        }
    }
}

.question:not(:first-child) heading {
    display: none;
}

.question-text {
    padding-left: 5px;
    display: flex;
    align-items: center;
}


.answers {
    display: flex;
    align-content: center;
    align-items: center;
}

.fullwidth {
    width: 100%;
}

.answer {
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="text"],input[type="email"] {
    width: 100%;
    margin-bottom: 6px;
}

input[type="radio"] {
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: 6px;
}

#mail-data {
    width: 400px;
}

.flex-container {
    display: flex;
}

#opening {
    position: relative;
    border: 5px solid white;
    height: 50%;
    top: 35%;
    margin: auto;
    width: 65%;
    border-radius: 20px;
}

#site-title {
    position: relative;
    color: white;
    font-size: 60px;
}

.fullheight {
    height: 100%;
}

#dashboard-menu {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1;

    ul {
        list-style-type: none;
    }
}

.footer-info {
    position: absolute;
    bottom: 82px;
    left: 22px;
    font-style: italic;
}

.version-info {
    position: absolute;
    bottom:5px;
    left: 22px;
    font-style: italic;
    color: #DDDDDD;
}

.error {
    color: red;
}
textarea.error{
    border: 1px solid red;
}

#legend {
    display: none;
    border-radius: 10px;
    border: 1px solid gray;
    padding: 10px;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 0.8em;
    color: black;
    background-color: white;
    margin: 0;
    z-index: 99;
    ol{
        padding: 0;
        margin: 0 0 0 12px;
    }
    li {
        padding: 0;
        margin: 0;
    }
}

.smiley-img{
    width: 40px;
    height: 40px;
    //display: inline-block;

}

.poll-answer{
    width: 99%;
}
