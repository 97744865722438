// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Border
$border-color: #58C1A8;

// Scan body text
$text-color: #222222;

// Scan text in colored background
$text-bg-color: #4B6B5D;

//checkmark score, th background, button-background on hover
$dark-color: #EA5952;

//border, button-background
$medium-color: #EA5952;

// td background
$light-color: #C0CCC9;

$redcolor: #E95852;
$greencolor: #58C1A9;
$orangecolor: #ED8B47;
