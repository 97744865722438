@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '../app-base';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '../../css/muli.css';

#main {
    background-image: url("../images/background-teamscan.jpg");
}

.main-button {
    background-color: $dark-color;
    border-color: $dark-color;
}

.main-button:hover {
    background-color: $medium-color;
    border-color: $medium-color;
}

#logo img {
    width: 200px;
}

#zorgcompscanlogo{
    display: none;
}

.is-invalid{
    border: 1px solid red;
}
